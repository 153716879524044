import { PageProps } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import Layout from '../components/layout'

const Error404Page: React.FC<PageProps> = () => {
  const { t } = useTranslation('custom')
  return (
    <Layout seo={{ title: t('Page 404') }}>
      <h1>Cette page n&apos;existe pas</h1>
    </Layout>
  )
}

export default Error404Page
